/* Page Setup + Mixins */
:root {
  --gutter: 16px;
  --pageMarginTotal: 24px;
  --pageMargin: calc(var(--pageMarginTotal) - (var(--gutter) / 2));
  --easing: cubic-bezier(0.45, 0, 0.55, 1);
  --easingOut: cubic-bezier(0.16, 1, 0.3, 1);
  --white: #ffffff;
  --secondary-20: #f2f0eb;
  --secondary-40: #e6e2de;
  --secondary-60: #a6a08c;
  --secondary-80: #332e34;
  --black: #1e1e1e;
  --ochre-20: #eab595;
  --ochre-40: #d8886c;
  --ochre-60: #ae7153;
  --ochre-80: #de5333;
  --ochre: #d23745;
}
@media all and (max-width: 650px) {
  :root {
    --gutter: 8px;
    --pageMarginTotal: 16px;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
  padding: 0;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
input,
button,
textarea,
select {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
.icon-left-chevron {
  width: 0.5712890625em;
}
b {
  font-weight: bold;
}
.columns {
  display: flex;
  flex-wrap: wrap;
  padding: 0 calc(var(--pageMarginTotal) - var(--gutter) / 2);
  width: 100%;
}
.columns.no-gutters {
  width: 100%;
  margin-left: 0;
}
.columns.no-gutters .col {
  padding: 0;
}
.col {
  padding: 0 calc(var(--gutter) / 2);
  position: relative;
}
.col > .columns {
  padding: 0;
  width: calc(100% + var(--gutter));
  margin-left: calc(var(--gutter) / 2 * -1);
}
.col:not([class*=col-]) {
  flex: 1;
}
@media all and (max-width: 650px) {
  .col:not([class*=col-]) {
    flex: auto;
    width: 100%;
  }
}
.col-1 {
  width: 8.333%;
}
.col-2 {
  width: 16.666%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.333%;
}
.col-5 {
  width: 41.666%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.333%;
}
.col-8 {
  width: 66.666%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.333%;
}
.col-11 {
  width: 91.666%;
}
.col-12 {
  width: 100%;
}
.offset-1 {
  margin-left: 8.333%;
}
.offset-2 {
  margin-left: 16.666%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333%;
}
.offset-5 {
  margin-left: 41.666%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333%;
}
.offset-8 {
  margin-left: 66.666%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333%;
}
.offset-11 {
  margin-left: 91.666%;
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-0 {
    margin-left: 0;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-1 {
    margin-left: 8.333%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-2 {
    margin-left: 16.666%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-3 {
    margin-left: 25%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-4 {
    margin-left: 33.333%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-5 {
    margin-left: 41.666%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-6 {
    margin-left: 50%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-7 {
    margin-left: 58.333%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-8 {
    margin-left: 66.666%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-9 {
    margin-left: 75%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-10 {
    margin-left: 83.333%;
  }
}
@media all and (max-width: 1024px) {
  .tablet-lndscp-offset-11 {
    margin-left: 91.666%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-1 {
    width: 8.333%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-2 {
    width: 16.666%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-3 {
    width: 25%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-4 {
    width: 33.333%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-5 {
    width: 41.666%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-6 {
    width: 50%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-7 {
    width: 58.333%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-8 {
    width: 66.666%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-9 {
    width: 75%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-10 {
    width: 83.333%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-11 {
    width: 91.666%;
  }
}
@media all and (max-width: 1024px) {
  .col-tablet-lndscp-12 {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-0 {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-1 {
    margin-left: 8.333%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-2 {
    margin-left: 16.666%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-3 {
    margin-left: 25%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-4 {
    margin-left: 33.333%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-5 {
    margin-left: 41.666%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-6 {
    margin-left: 50%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-7 {
    margin-left: 58.333%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-8 {
    margin-left: 66.666%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-9 {
    margin-left: 75%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-10 {
    margin-left: 83.333%;
  }
}
@media all and (max-width: 768px) {
  .tablet-offset-11 {
    margin-left: 91.666%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-1 {
    width: 8.333%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-2 {
    width: 16.666%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-3 {
    width: 25%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-4 {
    width: 33.333%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-5 {
    width: 41.666%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-6 {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-7 {
    width: 58.333%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-8 {
    width: 66.666%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-9 {
    width: 75%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-10 {
    width: 83.333%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-11 {
    width: 91.666%;
  }
}
@media all and (max-width: 768px) {
  .col-tablet-12 {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .offset-1,
.offset-2,
.offset-3,
.offset-4,
.offset-5,
.offset-6,
.offset-7,
.offset-8,
.offset-9,
.offset-10,
.offset-11,
.tablet-lndscp-offset-0,
.tablet-lndscp-offset-1,
.tablet-lndscp-offset-2,
.tablet-lndscp-offset-3,
.tablet-lndscp-offset-4,
.tablet-lndscp-offset-5,
.tablet-lndscp-offset-6,
.tablet-lndscp-offset-7,
.tablet-lndscp-offset-8,
.tablet-lndscp-offset-9,
.tablet-lndscp-offset-10,
.tablet-lndscp-offset-11,
.tablet-offset-0,
.tablet-offset-1,
.tablet-offset-2,
.tablet-offset-3,
.tablet-offset-4,
.tablet-offset-5,
.tablet-offset-6,
.tablet-offset-7,
.tablet-offset-8,
.tablet-offset-9,
.tablet-offset-10,
.tablet-offset-11 {
    margin-left: 0;
  }
}
@media screen and (max-width: 650px) {
  .col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-tablet-lndscp-1,
.col-tablet-lndscp-2,
.col-tablet-lndscp-3,
.col-tablet-lndscp-4,
.col-tablet-lndscp-5,
.col-tablet-lndscp-6,
.col-tablet-lndscp-7,
.col-tablet-lndscp-8,
.col-tablet-lndscp-9,
.col-tablet-lndscp-10,
.col-tablet-lndscp-11,
.col-tablet-lndscp-12,
.col-tablet-1,
.col-tablet-2,
.col-tablet-3,
.col-tablet-4,
.col-tablet-5,
.col-tablet-6,
.col-tablet-7,
.col-tablet-8,
.col-tablet-9,
.col-tablet-10,
.col-tablet-11,
.col-tablet-12 {
    width: 100%;
  }
}
@media all and (max-width: 650px) {
  .col-mobile-1 {
    width: 12.5%;
  }
}
@media all and (max-width: 650px) {
  .col-mobile-2 {
    width: 25%;
  }
}
@media all and (max-width: 650px) {
  .col-mobile-3 {
    width: 37.5%;
  }
}
@media all and (max-width: 650px) {
  .col-mobile-4 {
    width: 50%;
  }
}
@media all and (max-width: 650px) {
  .col-mobile-5 {
    width: 62.5%;
  }
}
@media all and (max-width: 650px) {
  .col-mobile-6 {
    width: 75%;
  }
}
@media all and (max-width: 650px) {
  .col-mobile-7 {
    width: 87.5%;
  }
}
@media all and (max-width: 650px) {
  .col-mobile-8 {
    width: 100%;
  }
}
@media all and (max-width: 650px) {
  .mobile-offset-0 {
    margin-left: 0;
  }
}
@media all and (max-width: 650px) {
  .mobile-offset-1 {
    margin-left: 12.5%;
  }
}
@media all and (max-width: 650px) {
  .mobile-offset-2 {
    margin-left: 25%;
  }
}
@media all and (max-width: 650px) {
  .mobile-offset-3 {
    margin-left: 37.5%;
  }
}
@media all and (max-width: 650px) {
  .mobile-offset-4 {
    margin-left: 50%;
  }
}
@media all and (max-width: 650px) {
  .mobile-offset-5 {
    margin-left: 62.5%;
  }
}
@media all and (max-width: 650px) {
  .mobile-offset-6 {
    margin-left: 75%;
  }
}
@media all and (max-width: 650px) {
  .mobile-offset-7 {
    margin-left: 87.5%;
  }
}
.show-tablet {
  display: none;
}
@media all and (max-width: 768px) {
  .show-tablet {
    display: flex;
  }
}
@media all and (max-width: 768px) {
  .hide-tablet {
    display: none;
  }
}
.show-small {
  display: none;
}
@media all and (max-width: 650px) {
  .show-small {
    display: flex;
  }
}
@media all and (max-width: 650px) {
  .hide-small {
    display: none !important;
  }
}
/* Colours */
.bg-white {
  background: var(--white);
}
.bg-secondary-20 {
  background: var(--secondary-20);
}
.bg-secondary-40 {
  background: var(--secondary-40);
}
.bg-secondary-60 {
  background: var(--secondary-60);
}
.bg-secondary-80 {
  background: var(--secondary-80);
}
.bg-black {
  background: var(--black);
}
.bg-ochre-20 {
  background: var(--ochre-20);
}
.bg-ochre-40 {
  background: var(--ochre-40);
}
.bg-ochre-60 {
  background: var(--ochre-60);
}
.bg-ochre-80 {
  background: var(--ochre-80);
}
.bg-ochre {
  background: var(--ochre);
}
.color-white {
  color: var(--white);
}
.color-secondary-20 {
  color: var(--secondary-20);
}
.color-secondary-40 {
  color: var(--secondary-40);
}
.color-secondary-60 {
  color: var(--secondary-60);
}
.color-secondary-80 {
  color: var(--secondary-80);
}
.color-black {
  color: var(--black);
}
.color-ochre-20 {
  color: var(--ochre-20);
}
.color-ochre-40 {
  color: var(--ochre-40);
}
.color-ochre-60 {
  color: var(--ochre-60);
}
.color-ochre-80 {
  color: var(--ochre-80);
}
.color-ochre {
  color: var(--ochre);
}
::selection {
  background: var(--ochre-80);
  color: var(--white);
}
/* Type */
@font-face {
  font-family: "GraphikWeb";
  src: url("Graphik-Extralight.36308405.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "GraphikWeb";
  src: url("Graphik-Regular.058b670d.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GraphikWeb";
  src: url("Graphik-Medium.3a88ae4f.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "GraphikWeb";
  src: url("Graphik-Semibold.5f00fe8c.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
h1,
.type-heading-1 {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.04em;
}
@media all and (max-width: 650px) {
  h1,
.type-heading-1 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}
h2,
.type-display {
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 84px;
  letter-spacing: -0.05em;
  margin-bottom: 16px;
}
@media all and (max-width: 650px) {
  h2,
.type-display {
    font-size: 32px;
    line-height: 36px;
  }
}
h3,
.type-heading-2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.04em;
}
@media all and (max-width: 650px) {
  h3,
.type-heading-2 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}
h3 + h3,
.type-heading-2 + h3 {
  margin-top: 1em;
}
h4,
.type-subheading-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;
}
@media all and (max-width: 650px) {
  h4,
.type-subheading-1 {
    font-size: 14px;
    line-height: 16px;
  }
}
h5,
.type-subheading-2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;
}
@media all and (max-width: 650px) {
  h5,
.type-subheading-2 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}
/* Grid Overlay */
.grid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.grid-overlay.show {
  opacity: 1;
  visibility: visible;
}
.grid-overlay .column-overlay {
  background: red;
  opacity: 0.1;
  height: 100vh;
}
/* General Styles */
body {
  font-family: "GraphikWeb", sans-serif;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.03em;
}
@media all and (max-width: 650px) {
  body {
    font-size: 16px;
    line-height: 20px;
  }
}
@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}
main {
  overflow: hidden;
}
main.no-intro {
  margin-top: 74px;
}
a {
  text-decoration: none;
  color: currentColor;
}
.card {
  padding: 20px;
  background: red;
}
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 16px;
  z-index: 15;
}
header nav {
  padding-top: 5px;
}
header nav ul {
  display: flex;
}
header nav ul li {
  margin-right: 12px;
}
header nav ul li a {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;
}
@media all and (max-width: 650px) {
  header nav ul li a {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}
header nav ul.end {
  justify-content: flex-end;
}
header.light {
  color: var(--white);
}
.header-logo {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;
}
@media all and (max-width: 650px) {
  .header-logo {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}
.header-logo.big {
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 84px;
  letter-spacing: -0.05em;
}
@media all and (max-width: 650px) {
  .header-logo.big {
    font-size: 32px;
    line-height: 36px;
  }
}
i {
  font-style: normal;
  font-weight: 200;
}
p + p {
  margin-top: 1em;
}
footer {
  background: var(--secondary-80);
  color: var(--white);
  min-height: 100vh;
  padding: 20px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
footer p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;
}
@media all and (max-width: 650px) {
  footer p {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}
@media all and (max-width: 650px) {
  footer ul {
    margin-top: 60px;
  }
}
footer ul li a {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.04em;
  position: relative;
}
@media all and (max-width: 650px) {
  footer ul li a {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}
footer ul li a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: var(--white);
  opacity: 0.2;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: 0.2s ease;
}
footer ul li a:hover:after {
  opacity: 1;
}
.page-header {
  min-height: 100vh;
  position: relative;
  color: var(--white);
}
@media all and (max-width: 650px) {
  .page-header {
    min-height: calc(100vh - 75px);
  }
}
.page-header .page-header-content {
  position: relative;
  z-index: 10;
  padding-top: 100px;
}
@media all and (max-width: 650px) {
  .page-header .page-header-content {
    padding-top: 55px;
  }
}
.page-header .header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.page-header .page-header-bottom {
  position: absolute;
  z-index: 5;
  bottom: 32px;
  left: var(--pageMarginTotal);
  width: 100%;
  max-width: 344px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;
}
@media all and (max-width: 650px) {
  .page-header .page-header-bottom {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}
.button {
  display: inline-block;
  background: var(--ochre-80);
  color: var(--white);
  padding: 10px 12px;
  border-radius: 50px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;
  transition: 0.3s ease;
}
@media all and (max-width: 650px) {
  .button {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}
.button:hover {
  background: var(--ochre-40);
}
.button.large {
  padding: 16px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;
}
@media all and (max-width: 650px) {
  .button.large {
    font-size: 14px;
    line-height: 16px;
  }
}
@media all and (max-width: 650px) {
  .button.large {
    padding: 8px 12px;
  }
}
.button.white {
  background: var(--white);
  color: var(--secondary-80);
}
.button.white:hover {
  color: var(--white);
  background: var(--ochre-80);
}
* + .button {
  margin-top: 20px;
}
section {
  padding: 24px 0 120px 0;
}
@media all and (max-width: 650px) {
  section {
    padding: 16px 0 40px 0;
  }
}
section.border-top {
  border-top: 1px solid var(--secondary-40);
}
section h2 {
  color: var(--secondary-80);
}
.arrow-cta {
  display: inline-flex;
  font-size: 16px;
  width: 32px;
  height: 32px;
  background: var(--ochre-80);
  color: var(--white);
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;
}
.arrow-cta .explore-more {
  display: block;
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: all 0.3s var(--easing);
  z-index: 0;
  pointer-events: none;
}
.arrow-cta .explore-more img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: rotate 5s linear infinite;
}
.arrow-cta:hover .explore-more {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
* + .arrow-cta {
  margin-top: 32px;
}
.video-block {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}
.video-block .thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s var(--easing);
}
.video-block .thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-block .video {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s var(--easing);
}
.video-block .controls {
  position: absolute;
  z-index: 10;
  bottom: 32px;
  left: var(--pageMarginTotal);
  transition: all 0.3s var(--easing);
}
.video-block.playing .thumbnail,
.video-block.playing .controls {
  opacity: 0;
  visibility: hidden;
}
.video-block.playing .video {
  opacity: 1;
  visibility: visible;
}
.testimonials-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.04em;
  color: var(--secondary-60);
}
@media all and (max-width: 650px) {
  .testimonials-title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}
.testimonials {
  background: var(--secondary-20);
  padding: 24px 0 33px;
  position: relative;
}
.testimonials .flickity-slider {
  transform: none !important;
}
.testimonials .testimonial {
  left: 0 !important;
  opacity: 0;
  transition: opacity 0.6s var(--easing);
  z-index: -1;
}
.testimonials .testimonial.is-selected {
  opacity: 1;
  z-index: 0;
  transform: none !important;
}
.testimonials .testimonial {
  width: 100%;
  min-height: 100%;
}
.testimonials .testimonial .testimonial-text {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.04em;
}
@media all and (max-width: 650px) {
  .testimonials .testimonial .testimonial-text {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}
.testimonials .testimonial .testimonial-spinner {
  padding-right: 20px;
  margin-top: 13px;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
@media all and (max-width: 650px) {
  .testimonials .testimonial .testimonial-spinner {
    width: 170px;
    height: 170px;
    padding-bottom: 0;
    margin-top: 200px;
    margin-left: auto;
    margin-bottom: 40px;
  }
}
.testimonials .testimonial .testimonial-spinner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}
.testimonials .testimonial .testimonial-footer {
  margin-top: 45px;
  position: absolute;
  left: var(--pageMargin);
  bottom: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;
}
@media all and (max-width: 650px) {
  .testimonials .testimonial .testimonial-footer {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}
.testimonials .testimonial.is-selected .testimonial-spinner img {
  animation: rotate 10s linear infinite;
}
.testimonials .progress {
  position: absolute;
  bottom: 32px;
  right: var(--pageMarginTotal);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}
.testimonials .progress .progress-ring {
  transform: rotate(-90deg);
}
.testimonials .progress .progress-ring .progress-ring__circle {
  stroke-dasharray: 107;
}
.logo-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  position: relative;
}
@media all and (max-width: 650px) {
  .logo-grid {
    gap: 8px;
  }
}
.logo-grid::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
}
.logo-grid .logo-block {
  display: block;
  border-bottom: 1px solid var(--secondary-40);
  width: calc((100% - 32px) / 3);
  padding-bottom: 45px;
  position: relative;
}
@media all and (max-width: 650px) {
  .logo-grid .logo-block {
    width: calc((100% - 8px) / 2);
  }
}
.logo-grid .logo-block .explore-more {
  display: block;
  width: 115px;
  height: 115px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: transform 0.8s var(--easingOut);
  pointer-events: none;
  z-index: 5;
}
.logo-grid .logo-block .explore-more img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: rotate 5s linear infinite;
}
.logo-grid .logo-block:hover .explore-more {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.logo-grid .logo-block p {
  color: var(--secondary-80);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
}
.logo-grid .logo-block .logo-block-icon {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-grid .logo-block .logo-block-icon img {
  max-width: 80%;
}
.media-block.natural-height {
  height: auto;
  width: 100%;
}
.media-block.natural-height img {
  display: block;
  width: 100%;
}
.questionnaire-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(222, 83, 51, 0.2);
}
.questionnaire-progress .bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--ochre-80);
}
.questionnaire-main {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: calc(100vh - 74px);
}
.questionnaire-header {
  border-bottom: 1px solid var(--secondary-40);
  padding: 12px 0;
}
.questionnaire-body {
  flex: 1;
  display: flex;
  align-items: flex-start;
  position: relative;
}
.questionnaire-body:after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--secondary-40);
}
.questionnaire-body .left,
.questionnaire-body .right {
  flex: 1;
  overflow-y: auto;
}
.questionnaire-body .question-prompt {
  padding: 12px var(--pageMarginTotal);
}
.questionnaire-footer {
  display: flex;
  align-items: flex-end;
  position: relative;
}
.questionnaire-footer:after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--secondary-40);
}
.questionnaire-footer .left,
.questionnaire-footer .right {
  flex: 1;
  padding-bottom: 24px;
}
.questionnaire-footer .previous-question {
  width: 36px;
  height: 36px;
  display: block;
  border-radius: 100%;
  border: 1px solid var(--secondary-40);
  color: var(--ochre-80);
  margin: 0 0 0 var(--pageMarginTotal);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.questionnaire-footer .previous-question:hover {
  background: var(--ochre-80);
  border-color: var(--ochre-80);
  color: white;
}
.questionnaire-footer .next-question {
  margin: 0 0 0 16px;
}
fieldset {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}
.answer-fields.type-radio label {
  display: block;
  padding: 12px 16px;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.04em;
  border-bottom: 1px solid var(--secondary-40);
}
@media all and (max-width: 650px) {
  .answer-fields.type-radio label {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}
.answer-fields.type-radio label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.answer-fields.type-radio label .checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  height: 20px;
  width: 20px;
  background-color: var(--white);
  border: 1px solid var(--secondary-40);
  color: var(--ochre-80);
  border-radius: 50%;
}
.answer-fields.type-radio label .checkmark .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  font-size: 8px;
  height: 8px;
  transition: all 0.2s var(--easingOut);
}
.answer-fields.type-radio label:hover, .answer-fields.type-radio label.selected {
  background: var(--ochre-80);
  color: var(--white);
}
.answer-fields.type-radio label input:checked ~ .checkmark {
  border-color: var(--white);
}
.answer-fields.type-radio label input:checked ~ .checkmark .icon {
  transform: translate(-50%, -50%) scale(1);
}
/*# sourceMappingURL=index.e8ecd403.css.map */
