/* Page Setup + Mixins */

$breakWide: 1648px;
$breakTabletLndscp: 1024px;
$breakTablet: 768px;
$breakSmall: 650px;

:root {
  --gutter: 16px;
  --pageMarginTotal: 24px;
  --pageMargin: calc(var(--pageMarginTotal) - (var(--gutter) / 2));
  --easing: cubic-bezier(0.45, 0, 0.55, 1);
  --easingOut: cubic-bezier(0.16, 1, 0.3, 1);
  --white: #ffffff;
  --secondary-20: #f2f0eb;
  --secondary-40: #e6e2de;
  --secondary-60: #a6a08c;
  --secondary-80: #332e34;
  --black: #1e1e1e;
  --ochre-20: #eab595;
  --ochre-40: #d8886c;
  --ochre-60: #ae7153;
  --ochre-80: #de5333;
  --ochre: #d23745;

  @media all and (max-width: $breakSmall) {
    --gutter: 8px;
    --pageMarginTotal: 16px;
  }
}

@import "reset";
@import "grid";

/* Colours */

.bg-white {
  background: var(--white);
}
.bg-secondary-20 {
  background: var(--secondary-20);
}
.bg-secondary-40 {
  background: var(--secondary-40);
}
.bg-secondary-60 {
  background: var(--secondary-60);
}
.bg-secondary-80 {
  background: var(--secondary-80);
}
.bg-black {
  background: var(--black);
}
.bg-ochre-20 {
  background: var(--ochre-20);
}
.bg-ochre-40 {
  background: var(--ochre-40);
}
.bg-ochre-60 {
  background: var(--ochre-60);
}
.bg-ochre-80 {
  background: var(--ochre-80);
}
.bg-ochre {
  background: var(--ochre);
}
.color-white {
  color: var(--white);
}
.color-secondary-20 {
  color: var(--secondary-20);
}
.color-secondary-40 {
  color: var(--secondary-40);
}
.color-secondary-60 {
  color: var(--secondary-60);
}
.color-secondary-80 {
  color: var(--secondary-80);
}
.color-black {
  color: var(--black);
}
.color-ochre-20 {
  color: var(--ochre-20);
}
.color-ochre-40 {
  color: var(--ochre-40);
}
.color-ochre-60 {
  color: var(--ochre-60);
}
.color-ochre-80 {
  color: var(--ochre-80);
}
.color-ochre {
  color: var(--ochre);
}

::selection {
  background: var(--ochre-80);
  color: var(--white);
}

/* Type */

@font-face {
  font-family: "GraphikWeb";
  src: url("../fonts/Graphik-Extralight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "GraphikWeb";
  src: url("../fonts/Graphik-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GraphikWeb";
  src: url("../fonts/Graphik-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GraphikWeb";
  src: url("../fonts/Graphik-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@mixin type-display {
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 84px;
  letter-spacing: -0.05em;

  @media all and (max-width: $breakSmall) {
    font-size: 32px;
    line-height: 36px;
  }
}

@mixin type-heading-1 {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.04em;
  @media all and (max-width: $breakSmall) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}

@mixin type-heading-2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.04em;
  @media all and (max-width: $breakSmall) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}

@mixin type-subheading-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;

  @media all and (max-width: $breakSmall) {
    font-size: 14px;
    line-height: 16px;
  }
}

@mixin type-subheading-2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;

  @media all and (max-width: $breakSmall) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}

@mixin type-body {
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.03em;

  @media all and (max-width: $breakSmall) {
    font-size: 16px;
    line-height: 20px;
  }
}

h1,
.type-heading-1 {
  @include type-heading-1;
}

h2,
.type-display {
  @include type-display;
  margin-bottom: 16px;
}

h3,
.type-heading-2 {
  @include type-heading-2;
  + h3 {
    margin-top: 1em;
  }
}

h4,
.type-subheading-1 {
  @include type-subheading-1;
}

h5,
.type-subheading-2 {
  @include type-subheading-2;
}

/* Grid Overlay */

.grid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .column-overlay {
    background: red;
    opacity: 0.1;
    height: 100vh;
  }
}

/* General Styles */

body {
  font-family: "GraphikWeb", sans-serif;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include type-body;
}

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

main {
  overflow: hidden;

  &.no-intro {
    margin-top: 74px;
  }
}

a {
  text-decoration: none;
  color: currentColor;
}

.card {
  padding: 20px;
  background: red;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 16px;
  z-index: 15;
  nav {
    padding-top: 5px;
    ul {
      display: flex;
      li {
        margin-right: 12px;
        a {
          @include type-subheading-2;
        }
      }
      &.end {
        justify-content: flex-end;
      }
    }
  }

  &.light {
    color: var(--white);
  }
}

.header-logo {
  @include type-subheading-2;
  &.big {
    @include type-display;
  }
}

i {
  font-style: normal;
  font-weight: 200;
}

p + p {
  margin-top: 1em;
}

footer {
  background: var(--secondary-80);
  color: var(--white);
  min-height: 100vh;
  padding: 20px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    @include type-subheading-2;
  }

  ul {
    @media all and (max-width: $breakSmall) {
      margin-top: 60px;
    }
    li {
      a {
        @include type-heading-1;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: var(--white);
          opacity: 0.2;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 0;
          transition: 0.2s ease;
        }
        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.page-header {
  min-height: 100vh;
  position: relative;
  color: var(--white);
  @media all and (max-width: $breakSmall) {
    min-height: calc(100vh - 75px);
  }
  .page-header-content {
    position: relative;
    z-index: 10;
    padding-top: 100px;

    @media all and (max-width: $breakSmall) {
      padding-top: 55px;
    }
  }
  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .page-header-bottom {
    position: absolute;
    z-index: 5;
    bottom: 32px;
    left: var(--pageMarginTotal);
    width: 100%;
    max-width: 344px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: 15px;
    @include type-subheading-2;
  }
}

.button {
  display: inline-block;
  background: var(--ochre-80);
  color: var(--white);
  padding: 10px 12px;
  border-radius: 50px;
  @include type-subheading-2;
  transition: 0.3s ease;

  &:hover {
    background: var(--ochre-40);
  }

  &.large {
    padding: 16px 24px;
    @include type-subheading-1;
    @media all and (max-width: $breakSmall) {
      padding: 8px 12px;
    }
  }
  &.white {
    background: var(--white);
    color: var(--secondary-80);
    &:hover {
      color: var(--white);
      background: var(--ochre-80);
    }
  }
}

* + .button {
  margin-top: 20px;
}

section {
  padding: 24px 0 120px 0;

  @media all and (max-width: $breakSmall) {
    padding: 16px 0 40px 0;
  }

  &.border-top {
    border-top: 1px solid var(--secondary-40);
  }

  h2 {
    color: var(--secondary-80);
  }
}

.arrow-cta {
  display: inline-flex;
  font-size: 16px;
  width: 32px;
  height: 32px;
  background: var(--ochre-80);
  color: var(--white);
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;

  .explore-more {
    display: block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: all 0.3s var(--easing);
    z-index: 0;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      animation: rotate 5s linear infinite;
    }
  }

  &:hover {
    .explore-more {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

* + .arrow-cta {
  margin-top: 32px;
}

.video-block {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s var(--easing);
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .video {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s var(--easing);
  }
  .controls {
    position: absolute;
    z-index: 10;
    bottom: 32px;
    left: var(--pageMarginTotal);
    transition: all 0.3s var(--easing);
  }
  &.playing {
    .thumbnail,
    .controls {
      opacity: 0;
      visibility: hidden;
    }
    .video {
      opacity: 1;
      visibility: visible;
    }
  }
}

.testimonials-title {
  @include type-heading-1;
  color: var(--secondary-60);
}

.testimonials {
  background: var(--secondary-20);
  padding: 24px 0 33px;
  position: relative;

  .flickity-slider {
    transform: none !important;
  }

  .testimonial {
    left: 0 !important;
    opacity: 0;
    transition: opacity 0.6s var(--easing);
    z-index: -1;
  }

  .testimonial.is-selected {
    opacity: 1;
    z-index: 0;
    transform: none !important;
  }

  .testimonial {
    width: 100%;
    min-height: 100%;
    .testimonial-text {
      @include type-heading-1;
    }
    .testimonial-spinner {
      padding-right: 20px;
      margin-top: 13px;
      width: 100%;
      padding-bottom: 100%;
      position: relative;

      @media all and (max-width: $breakSmall) {
        width: 170px;
        height: 170px;
        padding-bottom: 0;
        margin-top: 200px;
        margin-left: auto;
        margin-bottom: 40px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .testimonial-footer {
      margin-top: 45px;
      position: absolute;
      left: var(--pageMargin);
      bottom: 0;
      @include type-subheading-2;
    }
    &.is-selected {
      .testimonial-spinner {
        img {
          animation: rotate 10s linear infinite;
        }
      }
    }
  }

  .progress {
    position: absolute;
    bottom: 32px;
    right: var(--pageMarginTotal);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    .progress-ring {
      transform: rotate(-90deg);
    }

    .progress-ring .progress-ring__circle {
      stroke-dasharray: 107;
      // stroke-dashoffset: 107;
    }
  }
}

.logo-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  position: relative;
  @media all and (max-width: $breakSmall) {
    gap: 8px;
  }
  &::after {
    // Ugly fix to remove bottom item borders without clipping or overflow
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .logo-block {
    display: block;
    border-bottom: 1px solid var(--secondary-40);
    width: calc((100% - (16px * 2)) / 3);
    padding-bottom: 45px;
    position: relative;

    @media all and (max-width: $breakSmall) {
      width: calc((100% - 8px) / 2);
    }

    .explore-more {
      display: block;
      width: 115px;
      height: 115px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      transition: transform 0.8s var(--easingOut);
      pointer-events: none;
      z-index: 5;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        animation: rotate 5s linear infinite;
      }
    }
    &:hover {
      .explore-more {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
    p {
      color: var(--secondary-80);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.03em;
    }
    .logo-block-icon {
      margin-top: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 80%;
      }
    }
  }
}

.media-block {
  &.natural-height {
    height: auto;
    width: 100%;
    img {
      display: block;
      width: 100%;
    }
  }
}

.questionnaire-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(222, 83, 51, 0.2);
  .bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--ochre-80);
  }
}

.questionnaire-main {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: calc(100vh - 74px);
}
.questionnaire-header {
  border-bottom: 1px solid var(--secondary-40);
  padding: 12px 0;
}
.questionnaire-body {
  flex: 1;
  display: flex;
  align-items: flex-start;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary-40);
  }
  .left,
  .right {
    flex: 1;
    overflow-y: auto;
  }
  .question-prompt {
    padding: 12px var(--pageMarginTotal);
  }
}

.questionnaire-footer {
  display: flex;
  align-items: flex-end;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary-40);
  }
  .left,
  .right {
    flex: 1;
    padding-bottom: 24px;
  }
  .previous-question {
    width: 36px;
    height: 36px;
    display: block;
    border-radius: 100%;
    border: 1px solid var(--secondary-40);
    color: var(--ochre-80);
    margin: 0 0 0 var(--pageMarginTotal);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    &:hover {
      background: var(--ochre-80);
      border-color: var(--ochre-80);
      color: white;
    }
  }
  .next-question {
    margin: 0 0 0 16px;
  }
}

fieldset {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

.answer-fields {
  &.type-radio {
    label {
      display: block;
      padding: 12px 16px;
      cursor: pointer;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      @include type-heading-1;
      border-bottom: 1px solid var(--secondary-40);
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        height: 20px;
        width: 20px;
        background-color: var(--white);
        border: 1px solid var(--secondary-40);
        color: var(--ochre-80);
        border-radius: 50%;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          font-size: 8px;
          height: 8px;
          transition: all 0.2s var(--easingOut);
        }
      }
      &:hover,
      &.selected {
        background: var(--ochre-80);
        color: var(--white);
      }
      input:checked ~ .checkmark {
        border-color: var(--white);
        .icon {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}

// .results-module {
//   pointer-events: none;
// }
